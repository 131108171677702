




.test{
    background: #46dd0b !important;
    color: #FFCC00 !important;
    border: solid !important;
    border-width: 1px !important;
}


.prova{
    width: 100px!important;
   height: 100px!important;
    background: #FFCC00 !important;
}

.gm-ui-hover-effect {
    display: none !important;
}

/* styles.css */

.checkout-form {
    color: white;
}
.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black; /* Colore di sfondo */
    color:yellow; /* Colore del testo */
    text-align: center;
    padding: 1rem; /* Padding */
    border-top: 1px solid #333; /* Bordo superiore */
    font-family: 'Poppins', sans-serif; /* Famiglia di font */
    z-index: 1000; /* Assicurarsi che il footer sia sopra gli altri elementi */
}


.cropper-container .advanced-cropper-canvas {
    width: 100% !important;
    height: auto !important;
}
.gm-style-iw-tc::after{
    background-color: #000000 !important;

}
.gm-style .gm-style-iw-c  {
    padding-right: 10px !important;
    padding-bottom: 0px !important;
    max-width: 500px !important;
    max-height: 326px !important;
    min-width: 0px !important;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    border: none !important;
    outline:none !important;
    top: 0;
    left: 0;
    transform: translate(-50%,-100%);
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}


