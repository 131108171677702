@import url('css/popOverLogin.css');
@import url('css/DrawerSettings.css');
@import url('css/inputStyle.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    display: none;
}

.delivery-tooltip {
    font-size: 14px !important; /* Dimensione del testo */
    border: 1px solid black !important; /* Bordo nero */
    background-color: #222 !important; /* Sfondo scuro */
    color: white !important; /* Colore del testo */
    padding: 10px !important; /* Spaziatura interna */
    border-radius: 5px !important; /* Angoli arrotondati */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important; /* Ombra */
    z-index: 1000; /* Sovrapposizione */
}

@font-face {
    font-family: 'bold';
    src: url('./fonts/Poppins-Bold.ttf') format('opentype') ;
}


@font-face {
    font-family: 'Shadows Into Light';
    src: url('./fonts/ShadowsIntoLight-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'semi-bold';
    src: url('./fonts/Poppins-SemiBold.ttf') format('opentype') ;
}

@font-face {
    font-family: 'regular';
    src: url('./fonts/Poppins-Regular.ttf') format('opentype') ;
}

@font-face {
    font-family: 'RobotoMono-Bold';
    src: url('./fonts/RobotoMono-Bold.ttf') format('opentype') ;
}

@font-face {
    font-family: 'RobotoMono-SemiBold';
    src: url('./fonts/RobotoMono-SemiBold.ttf') format('opentype') ;
}

@font-face {
    font-family: 'RobotoMono-Regular';
    src: url('./fonts/RobotoMono-Regular.ttf') format('opentype');
}


@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
