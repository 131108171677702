.notification-container {
    animation: run 1s;
}

.button {
    background: #F2F2F2;
}

.notification_success {
    background-color: #0C7040;
}

.success {
    filter: invert(21%) sepia(18%) saturate(2407%) hue-rotate(114deg) brightness(103%) contrast(104%);
}

.notification_fail {
    background-color: #C72C41;
}

.fail {
    filter: invert(11%) sepia(32%) saturate(7436%) hue-rotate(326deg) brightness(102%) contrast(95%);
}

.notification_warning {
    background-color: #FC8621;
}

.warning {
    filter: invert(29%) sepia(72%) saturate(1323%) hue-rotate(351deg) brightness(99%) contrast(96%);
}

@keyframes run {
    0% {
        left: -15%;
    }
    100% {
        left: 0;
    }
}