.labelStyle {
    font-size: 14px;
    font-family: regular, serif !important;
    margin-left: 10px;
    color: #faf9f9 !important;
}

.labelStyleBlack {
    font-size: 14px;
    font-family: regular, serif !important;
    margin-left: 10px;
    color: #000000 !important;
}

.inputStyle {
    border: none !important;
    height: 100% !important;
    background-color: transparent !important;
}

.inputStyle::placeholder {
    color: black !important;
}

.wrapperStyle {
    border-radius: 10px;
    height: 100%;
    background-color: rgba(242, 242, 242, 0.66);
}

.wrapperStyleWhite {
    border-radius: 10px;
    height: 100%;
    background-color: rgb(255, 255, 255);
}


.mantine-InputWrapper-root {
    height: 100%;
}

.selectInputPayment {
    border: none !important;
    font-family: regular, serif !important;
}

.selectOptionsPayment {
    font-family: regular, serif !important;
}

.indicatorTest {
    outline: 0.1px solid rgba(0, 0, 0, 0.32);

    &[data-active] {
        outline: 0.1px solid rgba(0, 0, 0, 0.55);
    }
}


input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}

