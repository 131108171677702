@tailwind base;
@tailwind components;
@tailwind utilities;

button[data-progress='true'].stepCustom0 {
    @apply bg-yellow rounded-[6px]
}

/*To eliminate the Orario, Persone, etc label when not selected*/
button:not([data-progress='true']):not([data-completed='true']).stepCustom0 .mantine-Stepper-stepBody {
    @apply hidden
}

/*add this classes only if the button is not active to set the arrow*/
button:not([data-progress='true']):not([data-completed='true']) + .mantine-Stepper-separator.separatorCustom0 {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.23853 1.6001L6.23853 6.6001L1.23852 11.6001' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    @apply mx-0 h-[13px] my-auto max-w-[7.4px] bg-white border-none bg-no-repeat
}

/*On button ACTIVE, selecting the stepper*/
button[data-progress='true'] + .mantine-Stepper-separator.separatorCustom0 {
    @apply bg-yellow
    border-t-[8px] border-t-white
    border-l-[10px] border-l-yellow
    border-b-[8px] border-b-white mx-0
    max-w-[10px]

}

[data-active='true'].stepCustom0 {
    @apply border-l-yellow
}

[data-completed='true'].stepCustom0 {
    @apply bg-yellow rounded-[6px]
}

/*When button is completed, the stepper:*/
button[data-completed='true'] + .mantine-Stepper-separator.separatorCustom0 {
    background: linear-gradient(#FFCC00 0% 10%, black 10% 90%, #FFCC00 90% 100%);
    @apply m-0 h-[52px] max-w-[1px] w-[1px] min-w-[1px]
}

button[data-completed='true']:has(+ .mantine-Stepper-separator:last-of-type + button[data-progress='true']:last-of-type).stepCustom0 {
    @apply !rounded-r-[6px]
}

.mantine-Stepper-separator.separatorCustom0:last-of-type:has(+ button[data-progress='true']:last-of-type) {
    @apply bg-none bg-yellow
    border-t-[8px] border-t-white
    border-l-[10px] border-l-yellow
    border-b-[8px] border-b-white mx-0
    max-w-[10px] h-0
}

button[data-completed='true'] .mantine-Stepper-stepWrapper.customWrapper0,
button[data-progress='true'] .mantine-Stepper-stepWrapper.customWrapper0 {
    @apply mr-0 ml-1
}

.customWrapper0:last-of-type {
    @apply !mx-0
}

.customWrapper0 img {
    @apply size-[30px]
}

button[data-progress='true'].mantine-Stepper-step:last-child.stepCustom0 {
    @apply bg-white
}

button[data-completed='true'].mantine-Stepper-step.stepCustom0 {
    @apply rounded-none
}

button[data-progress='true'].mantine-Stepper-step.stepCustom0 {
    @apply rounded-l-none
}

button[data-completed='true'].mantine-Stepper-step:last-child.stepCustom0 {
    @apply rounded-s-none bg-yellow
}

.mantine-Stepper-steps:has(button[data-completed='true'].stepCustom0:last-child) {
    @apply bg-yellow;
}

/*DatePicker*/
[data-month-level='true'], [data-year-level='true'] {
    @apply flex w-full flex-col content-center
}

.mantine-DatePicker-day[data-selected='true'].customDatePicker0 {
    @apply bg-yellow bg-opacity-50 rounded-full
}

button[data-disabled]:hover.customDatePicker0 {
    @apply !bg-transparent
}

